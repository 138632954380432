import CustomCheckBox from '@/components/customCheckBox';
import Form from '@/components/form';
import { ModalFormWrapper } from '@/components/form/modal';
import PageLinkComponent from '@/components/page/linkComponent';
import SwipeableTabViews, { SegmentControlStyles } from '@/components/swipeableTabViews';
import { mutateGraphQL } from '@/data';
import { PurchaseWrite } from '@/data/commerce/purchase.graphql';
import { LineItemsWrite } from '@/data/management/lineItem.graphql';
import PriceView from '@/modals/PriceView';
import { createItemsFromLineItem } from '@/pages/dashboard/commerce/purchases/actions/receivingActions';
import PurchaseSelect from '@/pages/formSelects/purchaseSelect';
import VendorSelect from '@/pages/formSelects/vendorSelect';
import { invoiceLineItemsSelection } from '@/pages/p/commerce/invoiceEstimate/clientView/invoiceSelection';
import useUserInfo from '@/providers/auth/useUserInfo';
import {
	LineItem,
	Menu,
	MutationLineItemsWriteArgs,
	MutationMenuWriteArgs,
	MutationPurchaseWriteArgs,
	Order, Purchase,
} from '@/types/schema';
import { gql } from '@apollo/client';
import {
	Chip,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { isEmpty, map, pick, sum, toLower, uniq, uniqBy } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import currencyFormat from '../helpers/currencyFormat';

export default function PurchaseFromInvoice( {
	invoice,
	lineItems,
	selectionsMenu,
	type = 'Invoice',
}: {
	invoice: Order,
	lineItems: LineItem[],
	selectionsMenu: string[],
	type: 'Invoice' | 'Order'
} ) {
	
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	const router = useRouter();
	const selections = selectionsMenu ? [ ...selectionsMenu ] : invoice.company.metadata?.selectionsMenu || [];
	const uniqueCategoriesLineItems: LineItem[] = uniqBy( lineItems, 'category.name' );
	const {
		      showUnit,
		      showName,
		      showTotal,
		      showPrice,
		      showQuantity,
		      showCategory,
		      showCode,
		      showSku,
		      expandedLines,
		      showTaxedLineItems,
	      } = invoiceLineItemsSelection( selections );
	
	return (
		<Form<{ selectedLineItems: LineItem[], menu: Menu | null, purchase: Purchase | null, tab: number }>
			initialValues={{ selectedLineItems: [], menu: null, purchase: null, tab: 0 }}
			onSubmit={async ( values ) => {
				try {
					enqueueSnackbar( `Please wait while the purchase is being ${values.tab === 0
						? 'updated'
						: 'created'}....`, { variant: 'info' } );
					
					const lineItemsWithNoItems = values.selectedLineItems.filter( ( lineItem ) => !lineItem.item );
					
					const newItems = !isEmpty( lineItemsWithNoItems )
						? await createItemsFromLineItem( lineItemsWithNoItems, invoice?.companyLocation, staff )
						: {};
					
					let modifiedPurchase = values.purchase;
					
					// add lineitems to an existing purchase
					if ( values.tab === 0 ) {
						if ( !modifiedPurchase?.id ) throw 'Please select a Purchase Order to add these lineItems';
						await mutateGraphQL<MutationLineItemsWriteArgs>( {
							mutation : LineItemsWrite,
							variables: {
								inputs: values.selectedLineItems.map( ( lineItem ) => ( {
									...pick( lineItem, [
										'name',
										'price',
										'cost',
										'image',
										'description',
										'unit',
										'metadata',
										'quantity',
										'sku',
									] ),
									purchase   : modifiedPurchase?.id,
									linkedOrder: invoice.id,
									category   : lineItem.category?.id ?? undefined,
									code       : lineItem.code ?? undefined,
									uom        : newItems[ lineItem.id ]?.uom?.id || lineItem.uom?.id || undefined,
									item       : newItems[ lineItem.id ]?.newItem?.id || lineItem.item?.id || undefined,
								} ) ) as any[],
							},
						} );
					} else {
						// create new purchase with new line items
						const { purchaseWrite } = await mutateGraphQL<MutationPurchaseWriteArgs>( {
							mutation : PurchaseWrite,
							variables: {
								customNumber: Boolean( staff.company.metadata?.customPurchaseNumber ),
								method      : `Purchase from ${type} ${invoice.number}`,
								input       : {
									staff          : staff?.id,
									menu           : values.menu?.id || null,
									companyLocation: invoice.companyLocation?.id || null,
									serviceDate    : new Date(),
									lineItems      : values.selectedLineItems.map( ( lineItem ) => ( {
										...pick( lineItem, [
											'name',
											'price',
											'cost',
											'image',
											'description',
											'unit',
											'metadata',
											'quantity',
											'sku',
										] ),
										linkedOrder: invoice.id,
										category   : lineItem.category?.id ?? undefined,
										code       : lineItem.code ?? undefined,
										uom        : newItems[ lineItem.id ]?.uom?.id || lineItem.uom?.id || undefined,
										item       : newItems[ lineItem.id ]?.newItem?.id || lineItem.item?.id || undefined,
									} ) ) as any[],
								},
							},
						} );
						modifiedPurchase = purchaseWrite;
					}
					
					// mark original line items as purchased
					await mutateGraphQL<MutationLineItemsWriteArgs>( {
						mutation : LineItemsWrite,
						variables: {
							inputs: values.selectedLineItems.map( ( lineItem ) => ( {
								id            : lineItem.id,
								name          : lineItem.name,
								purchased     : true,
								linkedPurchase: modifiedPurchase?.id,
							} ) ),
						},
					} );
					
					// add uoms to vendor
					if ( values.tab === 1 && modifiedPurchase && values.menu?.id ) {
						const uomIds = modifiedPurchase.lineItems.map( ( lineItem ) => lineItem.uom?.id ).filter( Boolean );
						const menuUomIds = values.menu.uoms?.map( ( uom ) => uom?.id ).filter( Boolean ) || [];
						
						if ( !isEmpty( uomIds ) ) {
							await mutateGraphQL<MutationMenuWriteArgs>( {
								mutation : gql`mutation MenuWrite_aa68($id: String, $input: MenuValidator, $method: String, $remove: Boolean) {
									menuWrite(id: $id, input: $input, method: $method,remove: $remove) {
										id
								}}`,
								variables: {
									id    : values.menu.id,
									method: 'Added vendor items',
									input : { uoms: uniq( [ ...menuUomIds, ...uomIds ] ) },
								},
							} ).catch( () => [] );
						}
						
					}
					await router.push( `/dashboard/commerce/purchases/${modifiedPurchase?.id}/edit` );
					
					enqueueSnackbar( 'Purchase created successfully.', { variant: 'success' } );
					
				} catch ( e ) {
					console.error( e );
					enqueueSnackbar( 'The purchase could not be created.', { variant: 'error' } );
				}
				
			}}>
			{( formik ) => {
				const selectedLineItems = formik.values.selectedLineItems;
				
				return (
					<ModalFormWrapper
						title='Create Purchase'
						saveButtonProps={{ disabled: !selectedLineItems?.length }}
						saveButtonText={formik.values.tab === 0 ? 'Add Items' : 'Create Purchase'}>
						<Stack spacing={2}>
							<SwipeableTabViews
								tab={formik.values.tab}
								renderTabs={[ 'Add to Existing Purchase', 'Select Vendor' ]}
								renderContent={( index ) => [
									<PurchaseSelect
										key='purchase'
										sx={{ width: { sm: '100%', md: '70%' } }}
										name='purchase'
										variables={{ options: { limit: 50, filter: { status: { $in: [ 'DRAFT', 'SENT' ] } } } }}
										onAdd={undefined}
									/>,
									<VendorSelect
										key='vendor'
										sx={{ width: { sm: '100%', md: '70%' } }}
										name='menu'
										variables={{ options: { limit: 50, filter: { active: true } } }}
										onAdd={undefined}
									/>,
								][ index ]}
								containerProps={{
									sx: {
										width         : { xs: '100%', sm: 400 },
										scrollbarWidth: 'none',
									},
								}}
								tabsWrapperProps={{ sx: { ...SegmentControlStyles } }}
								setTab={( index ) => formik.setFieldValue( 'tab', index )}
							/>
							<TableContainer
								sx={{
									'borderRadius'            : 2,
									'border'                  : 1,
									'borderColor'             : 'divider',
									'.MuiTypography-root'     : { fontSize: { xs: '12px', sm: '1.2333rem' } },
									'tbody > tr:last-child td': { borderBottom: 0 },
								}}>
								<Table size='small' cellSpacing={2}>
									<TableHead sx={{ bgcolor: 'divider', display: 'table-row-group' }}>
										<TableRow>
											<TableCell></TableCell>
											<TableCell sx={{ width: '30%' }}>
												{t( 'common:product' )}
											</TableCell>
											<TableCell>
												{showSku && t( 'common:sku' )}
											</TableCell>
											<TableCell>
												{showCode && t( 'common:code' )}
											</TableCell>
											<TableCell width='10%'>
												{showUnit && t( 'common:unit' )}
											</TableCell>
											<TableCell width='8%'>
												{showPrice && t( 'common:cost' )}
											</TableCell>
											<TableCell width='8%'>
												{showPrice && t( 'common:price' )}
											</TableCell>
											<TableCell width='8%'>
												{showQuantity && t( 'common:quantity' )}
											</TableCell>
											<TableCell align='right' colSpan={2}>
												{t( 'common:total' )}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{map( uniqueCategoriesLineItems, ( { category }, index ) => (
											<Fragment key={index || 'other'}>
												{showCategory && category?.name && (
													<TableRow>
														<TableCell></TableCell>
														<TableCell sx={{ pb: 0, pt: 1, pl: 2 }}>
															<Typography
																sx={{
																	color     : 'text.secondary',
																	fontSize  : '12px !important',
																	fontWeight: '500',
																}}>
																{category?.name}
															</Typography>
														</TableCell>
														<TableCell colSpan={5}></TableCell>
													</TableRow>
												)}
												{lineItems.filter( ( lineItem ) => lineItem.category?.name === category?.name )
													.map( ( lineItem, index ) => {
														const hasExternalTax = lineItem.prices?.find( ( price ) => price.metadata?.useTax && price.value > 0 );
														
														return (
															<Fragment key={index}>
																<TableRow>
																	<TableCell
																		sx={{ py: !expandedLines ? 0 : 'auto' }}>
																		<CustomCheckBox
																			checked={Boolean( selectedLineItems.find( ( li ) => li?.id === lineItem.id ) ) ?? false}
																			onChange={( e, checked ) => {
																				const newLineItems: LineItem[] = checked
																					? [ ...selectedLineItems, lineItem ]
																					: selectedLineItems.filter( ( li ) => li.id !== lineItem.id );
																				formik.setFieldValue( 'selectedLineItems', newLineItems );
																			}}
																		/>
																	</TableCell>
																	<TableCell sx={{ py: 0 }}>
																		<Stack direction='row' spacing={1}>
																			<Stack direction='row' spacing={1}>
																				<Typography>
																					{showName
																						? `${lineItem.name} ${showTaxedLineItems && ( lineItem.tax > 0 || hasExternalTax )
																							? '(T)'
																							: ''}`
																						: ''}
																				</Typography>
																				{lineItem?.linkedPurchase && (
																					<Typography
																						component={PageLinkComponent}
																						sx={{ color: 'primary.main' }}
																						target='_blank'
																						href={`https://${process.env.NEXT_PUBLIC_DEV
																							? 'dev'
																							: 'www'}.invoiss.com/p/${lineItem.linkedPurchase.id}/purchase`}>
																						{lineItem.linkedPurchase.metadata?.customNumber || lineItem.linkedPurchase.number}
																					</Typography>
																				)}
																			</Stack>
																			
																			{lineItem.purchased && (
																				<Chip
																					variant='alpha'
																					color='warning'
																					label='Purchased'
																				/>
																			)}
																			<Tooltip title={lineItem.status || ''}>
																				<Typography
																					sx={{
																						color: 'primary.main',
																					}}>
																					{lineItem.status}
																				</Typography>
																			</Tooltip>
																		</Stack>
																	</TableCell>
																	<TableCell>
																		{showSku ? lineItem.sku : ''}
																	</TableCell>
																	<TableCell>
																		{showSku ? lineItem.code : ''}
																	</TableCell>
																	<TableCell sx={{ py: !expandedLines ? 0 : 'auto' }}>
																		{showUnit ? lineItem.unit : ''}
																	</TableCell>
																	<TableCell sx={{ py: !expandedLines ? 0 : 'auto' }}>
																		{showPrice ? currencyFormat( lineItem.cost ) : ''}
																	</TableCell>
																	<TableCell sx={{ py: !expandedLines ? 0 : 'auto' }}>
																		{showPrice ? currencyFormat( lineItem.price ) : ''}
																	</TableCell>
																	<TableCell sx={{ py: !expandedLines ? 0 : 'auto' }}>
																		{showQuantity ? lineItem.quantity : ''}
																	</TableCell>
																	<TableCell
																		align='right'
																		colSpan={2}
																		sx={{ py: !expandedLines ? 0 : 'auto' }}>
																		{showTotal
																			? currencyFormat( ( lineItem.cost || 0 ) * lineItem.quantity )
																			: null}
																	</TableCell>
																</TableRow>
																{/* Modifier Groups*/}
																{lineItem.modifierGroups?.map( ( mGroup ) => mGroup.modifiers?.filter( ( modifier ) => lineItem.metadata?.[ modifier.externalId ] )
																	?.map( ( modifier, index ) => (
																		<Fragment key={index}>
																			<TableRow
																				sx={{
																					'.MuiTableCell-root': {
																						color     : 'text.secondary',
																						fontSize  : 12,
																						py        : 0,
																						lineHeight: !expandedLines ? 1 : 'auto',
																					},
																				}}>
																				<TableCell colSpan={5}>
																					{`${modifier.name} ${modifier.isPercent
																						? `(${modifier.value}%)`
																						: ` - ${currencyFormat( modifier.value )} ${lineItem.metadata?.[ modifier.externalId ]
																							? `x ${lineItem.metadata?.[ modifier.externalId ] * lineItem.quantity}`
																							: `x ${lineItem.quantity}`}`}`}
																				</TableCell>
																				<TableCell
																					align='right'>
																					{modifier.isPercent
																						? currencyFormat( modifier.value * ( lineItem.price * lineItem.quantity / 100 ) )
																						: currencyFormat( modifier.value * ( lineItem.metadata?.[ modifier.externalId ] || 1 ) * lineItem.quantity )}
																				</TableCell>
																			</TableRow>
																		</Fragment>
																	) ) )}
																{/* LineItem Prices */}
																{lineItem.prices?.filter( ( price ) => !price.metadata?.externalTax )
																	?.map( ( fee, index ) => {
																		const modifierTotal = lineItem.modifierGroups?.map( ( mGroup ) => mGroup.modifiers?.filter( ( modifier ) => lineItem.metadata?.[ modifier.externalId ] )
																			?.reduce( ( total,
																				modifier ) => total + modifier.value * lineItem.metadata?.[ modifier.externalId ], 0 ) );
																		return (
																			<Fragment key={index}>
																				<TableRow
																					sx={{
																						'.MuiTableCell-root': {
																							color: 'text.secondary',
																							py   : .2,
																						},
																					}}>
																					<TableCell colSpan={6}>
																						{`${fee.name} ${fee.isPercent
																							? `(${fee.value}%)`
																							: ` (${currencyFormat( fee.value )}) ${fee.quantity > 1
																								? `x ${fee.isPercent
																									? fee.quantity
																									: fee.quantity * lineItem.quantity}`
																								: `x ${fee.isPercent
																									? '1'
																									: lineItem.quantity}`}`
																						}`}
																					</TableCell>
																					<TableCell align='right'>
																						{fee.isPercent
																							? currencyFormat( fee.value / 100 * ( lineItem.price * lineItem.quantity + sum( modifierTotal ) ) )
																							: currencyFormat( fee.value * ( fee.quantity || 1 ) * lineItem.quantity )}
																					</TableCell>
																				</TableRow>
																			</Fragment>
																		);
																	} )}
															</Fragment>
														);
													} )}
											</Fragment>
										) )}
									</TableBody>
								</Table>
							</TableContainer>
						</Stack>
					
					</ModalFormWrapper>
				);
			}}
		</Form>
	);
	
}
