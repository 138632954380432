import CallEffect from '@/components/callEffect';
import EnhancedDisplay from '@/components/enhancedDisplay';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormTextField from '@/components/form/fields/textField';
import { mutateGraphQL } from '@/data';
import { CommerceRead, CommerceWrite } from '@/data/commerce/commerce.graphql';
import FormGraphqlProvider from '@/data/query/formGraphqlProvider';
import idPick from '@/helpers/idPick';
import { updateCloverInventory } from '@/pages/api/processor/manage/clover';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { LineItemValidator, MutationCommerceWriteArgs, Order, SimpleLineItem } from '@/types/schema';
import { Box, ButtonProps, ListItemText, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { atom, useAtom, useAtomValue } from 'jotai';
import { isEmpty, isNumber, keyBy, partition } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LineItemState {
	stock: boolean,
	soldQuantity?: number
}

function UpdateStockButton( { text, buttonProps, stockProgress }: {
	stockProgress: number,
	text: string,
	buttonProps?: ButtonProps
} ) {
	const theme = useTheme();
	
	const isDarkMode = theme.palette.mode === 'dark';
	
	return (
		<AsyncLoadingButton
			key='update-stock'
			color='primary'
			variant={isDarkMode ? 'outlined' : 'contained'}
			progress={stockProgress}
			sx={{
				position: 'relative',
				overflow: 'hidden',
			}}
			{...buttonProps}>
			{text}
		</AsyncLoadingButton>
	);
}

const stockLineItemsAtom = atom<Record<string, LineItemState>>( {} );

function StockButton( {
	formik,
	lineItem,
	isUpdatingStock,
	updateSingleStock,
}: {
	formik: any,
	lineItem: SimpleLineItem,
	isUpdatingStock: boolean,
	updateSingleStock: ( formik, lineItem ) => void
} ) {
	const lineItemsState = useAtomValue( stockLineItemsAtom );
	const hasNoUomAndNoItem = !lineItem.uom && !lineItem.item;
	
	return (
		<Box>
			<Tooltip title={hasNoUomAndNoItem ? 'No stock to update' : ''}>
				<AsyncLoadingButton
					key='action'
					variant='contained'
					disabled={hasNoUomAndNoItem || isUpdatingStock}
					color={lineItemsState[ lineItem.id ]?.stock ? 'warning' : 'primary'}
					onClick={() => updateSingleStock( formik, lineItem )}>
					{lineItemsState[ lineItem.id ]?.stock ? 'Reverse Stock' : 'Update Stock'}
				</AsyncLoadingButton>
			</Tooltip>
		</Box>
	);
}

export default function CommerceItemsUpdating( { commerce }: { commerce: Order } ) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const { closeModal } = useModalControls();
	const [ stockProgress, setStockProgressAtom ] = useState( 0 );
	
	const [ lineItemsState, setLineItemsState ] = useAtom( stockLineItemsAtom );
	const [ isUpdatingStock, setIsUpdatingStock ] = useState( false );
	const updateProgress = ( progress: number ) => {
		setStockProgressAtom( progress );
	};
	
	const updateStock = async ( commerce: Order, updateStock: boolean ) => {
		if ( isEmpty( commerce.lineItems ) ) return;
		
		try {
			const [ stockedLineItems, unStockedLineItems ] = partition( commerce.lineItems, ( { stocked } ) => stocked );
			
			if ( isEmpty( stockedLineItems ) && !updateStock ) {
				enqueueSnackbar( 'Stock has been reversed for all items', { variant: 'info' } );
				return;
			}
			
			if ( isEmpty( unStockedLineItems ) && updateStock ) {
				enqueueSnackbar( 'Stock has been updated for all items', { variant: 'info' } );
				return;
			}
			
			const isStocked = !updateStock;
			const gateway = commerce?.gateway || commerce?.companyLocation?.gateway;
			const lineItems = [ ...updateStock ? unStockedLineItems : stockedLineItems ];
			
			const onUpdateSuccess = async ( { progress, updatedLineItems }: { progress: number, updatedLineItems } ) => {
				updateProgress( progress );
				
				setLineItemsState(
					( prev ) => ( {
						...prev,
						...keyBy( updatedLineItems.map( ( x ) => ( {
							id          : x.id,
							stock       : updateStock,
							soldQuantity: x.soldQuantity!,
						} ) ), 'id' ),
					} ),
				);
			};
			
			const data = await updateCloverInventory( gateway, lineItems, isStocked, onUpdateSuccess, true, commerce.companyLocation );
			
			if ( data?.hasItems ) {
				await mutateGraphQL<MutationCommerceWriteArgs>( {
					mutation : CommerceWrite,
					variables: {
						id   : commerce.id,
						input: {
							metadata: { stock: !Boolean( isStocked ), partiallyStocked: false },
						},
					},
				} );
			}
			
			enqueueSnackbar( `Stock is ${updateStock
				? 'updated'
				: 'reversed'} successfully for the items.`, { variant: 'success' } );
			closeModal();
		} catch ( e ) {
			console.error( e );
			throw e;
		}
	};
	
	const updateSingleStock = async ( formik, lineItem: SimpleLineItem ) => {
		setIsUpdatingStock( true );
		
		try {
			const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/updateInventory`,
				{
					id       : formik.values.id,
					single   : true,
					stock    : lineItemsState[ lineItem.id ]?.stock,
					lineItems: [ {
						externalId  : lineItem.item?.externalId,
						item        : { externalId: lineItem.item?.externalId || null },
						quantity    : lineItem.quantity,
						soldQuantity: lineItem.soldQuantity || 0,
						uom         : {
							id      : lineItem.uom?.id,
							name    : lineItem.uom?.name,
							quantity: lineItem.uom?.quantity || 0,
						},
					} ],
				} );
			
			if ( data?.hasItems ) {
				const updatedItemState = {
					...lineItemsState,
					[ lineItem.id ]: {
						stock       : !lineItemsState[ lineItem.id ]?.stock,
						soldQuantity: lineItem.soldQuantity || 0,
					},
				};
				
				setLineItemsState( updatedItemState );
				
				const lineItems: LineItemValidator[] = formik.values.lineItems?.map( ( lineItem ) => {
					const updatedLineItem = updatedItemState[ lineItem.id ];
					return {
						...idPick( lineItem, [
							'name',
							'price',
							'cost',
							'image',
							'description',
							'unit',
							'quantity',
							'code',
							'externalId',
							'metadata',
						] ),
						soldQuantity: !updatedLineItem?.stock ? null : isNumber( updatedLineItem?.soldQuantity )
							? updatedLineItem?.soldQuantity
							: lineItem.soldQuantity,
						stocked     : updatedLineItem?.stock,
						// metadata    : {
						// 	...lineItem.metadata,
						// 	stock: updatedLineItem?.stock,
						// },
						uom : lineItem.uom?.id || null,
						item: lineItem.item?.id || null,
					};
				} );
				
				const allStocked = Object.values( updatedItemState ).every( ( value ) => value.stock );
				const [ stockedItems, unStockedItems ] = partition( updatedItemState, ( value ) => value.stock );
				
				const { commerceWrite } = await mutateGraphQL<MutationCommerceWriteArgs>( {
					mutation : CommerceWrite,
					variables: {
						id   : commerce.id,
						input: {
							metadata : {
								stock           : allStocked,
								partiallyStocked: !isEmpty( stockedItems ) && !isEmpty( unStockedItems ),
							},
							lineItems: lineItems,
						},
					},
				} );
				formik.setValues( commerceWrite );
				formik.setFieldValue( 'lineItems', commerceWrite.lineItems.map( ( lineItem ) => ( {
					...lineItem,
					soldQuantity: isNumber( lineItem.soldQuantity )
						? lineItem.soldQuantity
						: lineItem.quantity,
				} ) ) );
			}
		} catch ( e ) {
			console.error( 'Failed to update stock for line item:', e );
			enqueueSnackbar( `Error updating stock for item ${lineItem.name} .`, { variant: 'error' } );
		} finally {
			setIsUpdatingStock( false );
		}
	};
	
	const stateValues = Object.values( lineItemsState ).map( ( value ) => value.stock );
	return (
		<FormGraphqlProvider<Order>
			id={commerce.id}
			queryKey='commerce'
			query={CommerceRead}
			onSubmit={() => null}>
			{( formik ) => (
				<ResponsiveModalContainer
					title={`${t( 'commerce:update-stock' )} #${formik.values.metadata?.customNumber || formik.values.number}`}
					secondaryTitle={t( 'common:update-commerce-title' )}
					actionItems={(
						<Stack direction='row' alignItems='center' spacing={1}>
							<UpdateStockButton
								key='reverseStock'
								text='Reverse All Stock'
								stockProgress={stockProgress}
								buttonProps={{
									disabled: stateValues?.every( ( value ) => !value ),
									onClick : () => updateStock( formik.values, false ),
								}}
							/>
							<UpdateStockButton
								key='updateStock'
								text='Update All Stock'
								stockProgress={stockProgress}
								buttonProps={{
									variant : 'contained',
									color   : 'primary',
									disabled: stateValues?.every( ( value ) => value ),
									onClick : () => updateStock( formik.values, true ),
								}}
							/>
						</Stack>
					)}>
					<CallEffect
						func={() => {
							formik.setFieldValue( 'lineItems', formik.values.lineItems.map( ( lineItem ) => ( {
								...lineItem,
								soldQuantity: isNumber( lineItem.soldQuantity )
									? lineItem.soldQuantity
									: lineItem.quantity,
							} ) ) );
							setLineItemsState( formik.values.lineItems.reduce( ( obj, lineItem ) => ( {
								...obj,
								[ lineItem.id ]: {
									stock       : lineItem.stocked ?? false,
									soldQuantity: lineItem.soldQuantity || 0,
								},
							} ), {} ) );
							setIsUpdatingStock( false );
						}}
					/>
					<EnhancedDisplay
						data={formik.values.lineItems}
						extraData={isUpdatingStock}
						listProps={{
							renderRow: ( lineItem, index ) => (
								<Stack direction='row' width='100%'>
									<ListItemText
										primary={lineItem.name}
										primaryTypographyProps={{ variant: 'h5' }}
										secondary={(
											<Stack spacing={1}>
												<Stack>
													<Typography>
														UOM Qty: {lineItem.uom?.quantity || '-'}
													</Typography>
													<Typography>
														Item Qty: {lineItem.quantity}
													</Typography>
													<Typography>
														Unit: {lineItem.unit}
													</Typography>
												</Stack>
												<FormTextField
													disabled={lineItemsState[ lineItem.id ]?.stock}
													placeholder={t( 'common:quantity-sold' )}
													label={t( 'common:quantity-sold' )}
													type='number'
													name={`lineItems.${index}.soldQuantity`}
													format={( value ) => +value}
													sx={{
														'.MuiFormLabel-root': { pl: 0 },
													}}
													onFocus={( e ) => e.target.select()}
												/>
											</Stack>
										)}
									/>
									<StockButton
										formik={formik}
										lineItem={lineItem}
										isUpdatingStock={isUpdatingStock}
										updateSingleStock={updateSingleStock}
									/>
								</Stack>
							),
						}}
						tableProps={{
							hover    : false,
							headers  : [
								t( 'common:items' ),
								'Available',
								t( 'common:ordered' ),
								t( 'common:sold' ),
								'Action',
							],
							columns  : ( lineItem, index ) => [
								<Typography key='name'>
									{lineItem.name}
								</Typography>,
								<Typography key='uom-quantity'>
									{lineItem.uom?.quantity || '-'}
								</Typography>,
								<Stack key='unit-quantity' direction='row' alignItems='center' spacing={1}>
									<Typography>{lineItem.quantity} </Typography>
									<Typography>{lineItem.unit}</Typography>
								</Stack>,
								<Stack key='sold-quantity' direction='row' alignItems='center' spacing={1}>
									<FormTextField
										disabled={lineItemsState[ lineItem.id ]?.stock}
										placeholder={t( 'common:quantity-sold' )}
										type='number'
										name={`lineItems.${index}.soldQuantity`}
										format={( value ) => +value}
										onFocus={( e ) => e.target.select()}
									/>
									<Typography>{lineItem.unit}</Typography>
								</Stack>,
								<StockButton
									key='action'
									formik={formik}
									lineItem={lineItem}
									isUpdatingStock={isUpdatingStock}
									updateSingleStock={updateSingleStock}
								/>,
							],
							cellProps: [
								{ width: '30%' },
								{ width: '10%' },
								{ width: '10%' },
								{ width: '10%' },
								{ width: '10%', align: 'right' },
							],
						}}
					/>
				</ResponsiveModalContainer>
			
			)}
		</FormGraphqlProvider>
	);
}
